import helperService from '../services/helperService';

class SayHelloFormValidator {
  constructor() {
    this.form = document.querySelector('.say-hello form');
    this.submitButton = this.form.querySelector('.btn-send');
    this.nameInput = this.form.querySelector('#name');
    this.emailInput = this.form.querySelector('#email');
    this.messageInput = this.form.querySelector('#message');

    this.nameInput.addEventListener('keyup', (e) => {
      this.validateNotEmpty(e.target);
    });
    this.messageInput.addEventListener('keyup', (e) => {
      this.validateNotEmpty(e.target);
    });
    this.emailInput.addEventListener('keyup', (e) => {
      this.validateEmail(e.target);
    });
    this.addAutoResize();
  }

  addAutoResize() {
    const element = document.querySelector('[data-autoresize]');
    element.style.boxSizing = 'border-box';
    const offset = element.offsetHeight - element.clientHeight;
    element.addEventListener('input', (event) => {
      event.target.style.height = 'auto';
      event.target.style.height = `${event.target.scrollHeight + offset}px`;
    });
    element.removeAttribute('data-autoresize');
  }

  validate() {
    this.validateNotEmpty(this.nameInput, true);
    this.validateNotEmpty(this.messageInput, true);
    this.validateEmail(this.emailInput, true);

    const isValid = this.formIsValid();

    this.submitButton.disabled = !isValid;

    return isValid;
  }

  formIsValid() {
    return (
      !!this.nameInput.value
      && !!this.messageInput.value
      && helperService.emailIsValid(this.emailInput.value)
    );
  }

  validateNotEmpty(element, showErrors = false) {
    const fieldIsValid = !!element.value;

    if (fieldIsValid) {
      helperService.hideError(element);
    } else {
      showErrors && helperService.showError(element);
    }

    if (this.formIsValid()) {
      this.submitButton.disabled = false;
    }
  }

  validateEmail(element, showErrors = false) {
    const fieldIsValid = helperService.emailIsValid(element.value);

    if (fieldIsValid) {
      helperService.hideError(element);
    } else {
      showErrors && helperService.showError(element);
    }

    if (this.formIsValid()) {
      this.submitButton.disabled = false;
    }
  }

  clear() {
    this.nameInput.value = '';
    this.messageInput.value = '';
    this.emailInput.value = '';
  }
}

const sayHelloFormValidator = new SayHelloFormValidator();

export default sayHelloFormValidator;
