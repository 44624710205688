import { CONTACT, getUrl, headers } from './constants';

export const sendContact = (name, email, message) => fetch(getUrl(CONTACT), {
  method: 'post',
  headers,
  body: JSON.stringify({
    name,
    email,
    message,
  }),
});
