import HelperService from '../services/helperService';
import { JoinUsFormValidator } from '../JounUsFormValidator';
import * as rest from '../services/rest';
import * as services from './services';
import { hotVacancy } from './views/HotVacancies';
import { getFilter } from './views/Filter';
import { getFilteredVacanciesContainer } from './views/FilteredVacanciesContainer';
import { setSlidePanelContent } from './views/SlidePanelContent';
import { buttonCodes } from './constants';

export default class JoinUsController {
  constructor() {
    this.slidePanelIsOpen = false;
    this.isMobile = null;
    this.isIpad = null;
    this.formValidator = null;
    this.formContainer = null;
    this.slidePanelInfo = null;
    this.submitButton = null;
    this.allVacancies = {};
    this.openedVacancyId = null;
    this.hotVacanciesListElement = document.querySelector('.hot-vacancies-list');
    this.allVacanciesContainer = document.querySelector('.vacancies-container');
    this.filtersContainer = document.querySelector('.filters');
    this.slidePanelContainer = document.querySelector('.slide-side-panel-container');
    this.slidePanel = document.querySelector('.slide-side-panel');
    this.blackout = document.querySelector('.blackout');

    this.handleFormButtonClick = this.handleFormButtonClick.bind(this);
    this.closeSlidePanel = this.closeSlidePanel.bind(this);
    this.checkFormButton = this.checkFormButton.bind(this);
    this.onVacancyClickHandler = this.onVacancyClickHandler.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.hotVacanciesListElement.addEventListener('touchstart', this.stopPropagation, { passive: false });
    this.hotVacanciesListElement.addEventListener('touchend', this.stopPropagation);
    this.filtersContainer.addEventListener('touchstart', this.stopPropagation, { passive: false });
    this.filtersContainer.addEventListener('touchend', this.stopPropagation);

    if (this.hotVacanciesListElement && this.allVacanciesContainer) {
      this.renderVacancies();
      this.formRef = null;
    }
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  checkFormButton() {
    const whiteBlock = document.querySelector('.bottom-white-block');

    if (this.slidePanelInfo.scrollHeight
      - this.slidePanelInfo.clientHeight
      - this.slidePanelInfo.scrollTop < 150
    ) {
      this.formValidator.addFormButtonClass();
      whiteBlock.classList.add('bottom-white-block_sliced');
      return;
    }
    if (this.submitButton.classList.contains('edit') && this.formValidator.formIsEmpty()) {
      this.submitButton.classList.remove('edit');
      whiteBlock.classList.remove('bottom-white-block_sliced');
    }
  }

  handleFormButtonClick(e) {
    e.preventDefault();
    if (this.formValidator.formIsValid()) {
      this.handleSubmitForm(this.openedVacancyId);
    } else {
      services.animatedScrollToEnd(this.slidePanelInfo);
    }
  }

  handleSubmitForm(vacancyId) {
    const name = this.formRef.querySelector('#inputName').value;
    const email = this.formRef.querySelector('#inputEmail').value;
    const message = this.formRef.querySelector('#inputAdditionalInfo').value;

    this.submitButton.classList.remove('edit');
    this.submitButton.classList.add('sending');

    rest.applyVacancies(name, email, message, vacancyId)
      .then(() => {
        this.formContainer.innerHTML = `
            <div class="vacancy-card-common_form__applied">
              <p>
                Thanks for your apply!
              </p>
              <span>
                We’ll contact you as soon as possible.
              </span>
            </div>`;
        this.submitButton.classList.add('hide');
        document.querySelector('.bottom-white-block')
          .classList
          .add('applied');
      })
      .catch(() => {
        this.formContainer.innerHTML = `
            <div class="vacancy-card-common_form__applied">
              <p>
                Something went wrong!
              </p>
              <span>
                Please try again later.
              </span>
            </div>`;
      });
  }

  handleKeyDown(e) {
    if (e.key === buttonCodes.ESC) {
      this.closeSlidePanel();
    }
  }

  openSlidePanel(card) {
    this.setSlidePanelView(card);
    this.slidePanelContainer.classList.add('scrolled');
    this.formValidator = new JoinUsFormValidator(this.formRef, this.submitButton);
    this.slidePanel.querySelector('.slide-side-panel-menu')
      .classList
      .add('hide');
    this.slidePanel.querySelector('.vacancy-info-container')
      .classList
      .remove('hide');
    this.checkFormButton();
    document.body.addEventListener('keydown', this.handleKeyDown);
    this.slidePanel.classList.add('open-like-container');
    this.blackout.classList.add('active');
    this.slidePanelIsOpen = true;
    this.blackout.addEventListener('click', this.closeSlidePanel);
    this.slidePanel.querySelector('.left-arrow')
      .addEventListener('click', this.closeSlidePanel);
    this.submitButton.addEventListener('click', this.handleFormButtonClick);
    this.slidePanelInfo.addEventListener('scroll', this.checkFormButton);
  }

  closeSlidePanel() {
    this.slidePanelContainer.classList.remove('scrolled');
    this.blackout.classList.remove('active');
    this.slidePanel.classList.remove('open-like-container');
    this.slidePanelIsOpen = false;
    this.formValidator.destroy();
    this.formValidator = null;
    document.body.removeEventListener('keydown', this.handleKeyDown);
    this.blackout.removeEventListener('click', this.closeSlidePanel);
    this.submitButton.removeEventListener('click', this.handleFormButtonClick);
    this.slidePanelInfo.removeEventListener('scroll', this.checkFormButton);
    this.slidePanel.querySelector('.left-arrow')
      .removeEventListener('click', this.closeSlidePanel);
    setTimeout(() => {
      this.slidePanel.querySelector('.slide-side-panel-menu')
        .classList
        .remove('hide');
      this.slidePanel.querySelector('.vacancy-info-container')
        .classList
        .add('hide');
    }, 150);
    this.submitButton = null;
    this.openedVacancyId = null;
    document.activeElement.blur();
  }

  onFilterClickHandler(e) {
    const targetElement = e.target;
    if (targetElement.tagName.toLowerCase() !== 'button' && targetElement.tagName.toLowerCase() !== 'span') {
      return;
    }
    const targetClassName = e.target.classList.item(0);
    HelperService.removeClassFromElementChildes(this.filtersContainer, 'selected');
    HelperService.removeClassFromElementChildes(this.allVacanciesContainer, 'selected');
    this.filtersContainer.querySelector(`.${targetClassName}`)
      .classList
      .add('selected');
    this.allVacanciesContainer.querySelector(`.${targetClassName}-vacancies`)
      .classList
      .add('selected');
  }

  onVacancyClickHandler(target, id) {
    const tagName = target.tagName.toLowerCase();
    if (tagName !== 'span' && !this.isMobile && !this.isIpad) {
      return;
    }

    const selectedVacancy = this.allVacancies[id];

    selectedVacancy && this.openSlidePanel(selectedVacancy);
  }

  renderVacancies() {
    const vacancyRow = this.hotVacanciesListElement.querySelector('.vacancy-row');
    rest.getVacancies()
      .then((results) => {
        const mappedVacancies = services.mapVacansiesData(results);
        const categories = {};
        let countOfHotVacancies = 0;

        mappedVacancies.forEach((vacancy) => {
          const {
            category, title, tags, isHot, id
          } = vacancy;
          const categoryTitle = category.title;

          if (!categories[categoryTitle]) {
            categories[categoryTitle] = [];
          }

          if (isHot && countOfHotVacancies < 4) {
            const cardView = hotVacancy(title, tags);

            cardView.addEventListener('click', (e) => {
              this.stopPropagation(e);
              this.openSlidePanel(vacancy);
            });

            vacancyRow.append(cardView);
            countOfHotVacancies += 1;
          }

          categories[categoryTitle].push({
            title,
            id,
          });


          this.allVacancies[id] = vacancy;
        });

        this.renderFilters(categories);
      });
  }

  renderFilters(categories) {
    if (this.filtersContainer) {
      this.filtersContainer.addEventListener('click', this.onFilterClickHandler.bind(this));
      Object.entries(categories)
        .forEach((entry, index) => {
          const filterTitle = entry[0];
          const vacancies = entry[1];
          const vacanciesCount = vacancies.length;
          const isFilterSelected = index === 0;

          const button = getFilter(filterTitle, vacanciesCount, isFilterSelected);
          this.filtersContainer.appendChild(button);

          const vacanciesContainer = getFilteredVacanciesContainer(
            filterTitle,
            vacancies,
            this.onVacancyClickHandler,
            isFilterSelected,
          );
          this.allVacanciesContainer.appendChild(vacanciesContainer);
        });
    }
  }

  setSlidePanelView(vacancy) {
    const {
      id, sections, description, title,
    } = vacancy;
    const container = this.slidePanel.querySelector('.vacancy-info-container');
    container.innerHTML = setSlidePanelContent(sections, description, title);
    this.formRef = container.querySelector('form');
    this.formContainer = container.querySelector('.form-container');
    this.slidePanelInfo = container.querySelector('.info');
    this.submitButton = this.slidePanel.querySelector('.btn');
    this.openedVacancyId = id;
  }

  setDeviceType(isMobile, isIpad) {
    this.isMobile = isMobile;
    this.isIpad = isIpad;
  }
}
