const devUrl = 'http://localhost:9000/api/v1/';
const URL = `https://${window.location.host}/api/v1/`;

export const VACANCIES = 'vacancies/';

export const VACANCIES_APPLY = `${VACANCIES}/apply`;

export const CONTACT = 'contact';

export const headers = new Headers({
  'Content-Type': 'application/json',
});

const currentRequestUrl = process.env.NODE_ENV === 'development' ? devUrl : URL;

export const getUrl = (str) => {
  return `${currentRequestUrl}${str}`;
};
