export const clearSources = (backgrounds) => {
  backgrounds.forEach((background) => {
    const source = background.querySelector('source');
    if (source) {
      source.remove();
    }
  });
};

export const addSources = (backgrounds) => {
  backgrounds.forEach((background, index) => {
    const source = document.createElement('source');
    source.type = 'video/mp4';
    source.src = `assets/video${index + 1}.mp4`;
    background.querySelector('video')
      .append(source);
  });
};

export const changePage = (newPageElement, oldPageElement) => {
  if (!newPageElement || !oldPageElement) {
    return;
  }
  newPageElement.classList.remove('hide');
  oldPageElement.classList.add('hide');
};
