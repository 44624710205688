import sayHelloFormValidator from '../SayHelloFormValidator';
import * as rest from '../services/rest';
import helperService from '../services/helperService';

class SayHelloController {
  constructor() {
    const sayHelloPageContainer = document.querySelector('.say-hello');
    if (sayHelloPageContainer) {
      this.sayHelloForm = sayHelloPageContainer.querySelector('form');
      this.askSomethingBlock = sayHelloPageContainer.querySelector('.ask-questions');
      this.writeUsAgain = sayHelloPageContainer.querySelector('.write-us-again');
      this.writeUsAgainErrpr = sayHelloPageContainer.querySelector('.write-us-again-error');
      this.nameInput = this.sayHelloForm.querySelector('#name');
      this.emailInput = this.sayHelloForm.querySelector('#email');
      this.messageInput = this.sayHelloForm.querySelector('#message');
    }
    if (this.sayHelloForm && this.askSomethingBlock && this.writeUsAgain) {
      this.sayHelloForm.addEventListener('submit', this.submitFormHandler.bind(this));
      this.writeUsAgain.querySelector('button').addEventListener('click', this.writeUsAgainClickHandler.bind(this));
    }
  }

  writeUsAgainClickHandler() {
    this.writeUsAgain.classList.add('hide');
    sayHelloFormValidator.clear();
    this.askSomethingBlock.classList.remove('hide');
  }

  submitFormHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    const isValid = sayHelloFormValidator.validate();

    if (isValid) {
      const name = this.nameInput.value;
      const message = this.messageInput.value;
      const email = this.emailInput.value;
      const button = this.sayHelloForm.querySelector('button');

      button.classList.add('sending');
      rest.sendContact(name, email, message)
        .then(() => {
          this.writeUsAgain.classList.remove('hide');
          this.askSomethingBlock.classList.add('hide');
          button.classList.remove('sending');
        })
        .catch(() => {
          this.writeUsAgainErrpr.classList.remove('hide');
          this.askSomethingBlock.classList.add('hide');
        });
    }
  }
}

const sayHelloController = new SayHelloController();

export default sayHelloController;
