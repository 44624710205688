const getVacancy = (vacancyTitle, id, onClick) => {
  const div = document.createElement('div');
  div.innerHTML = `
              <span>${vacancyTitle}</span>
              <img src="./assets/ArrowRight.svg" alt="${vacancyTitle}" >
            `;
  div.addEventListener('click', (e) => onClick(e.target, id));

  return div;
};

export const getFilteredVacanciesContainer = (filterTitle, vacancies, onVacancyClick, isSelected) => {
  const vacanciesContainer = document.createElement('div');
  vacanciesContainer.classList.add(`${filterTitle}-vacancies`);

  vacancies.forEach((vacancy) => {
    const {title, id} = vacancy;
    const vacancyView = getVacancy(title, id, onVacancyClick);
    vacanciesContainer.appendChild(vacancyView);
  });

  isSelected && vacanciesContainer.classList.add('selected');

  return vacanciesContainer;
};
