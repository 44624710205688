import rightArrow from '../../../../assets/arrow-right-grey.svg';

export const hotVacancy = (title, tags) => {
  let hashTags = '';
  tags.forEach((tag) => {
    hashTags = hashTags.concat(`<span>#${tag.title}</span>`);
  });
  const div = document.createElement('div');
  div.classList.add('col');
  div.classList.add('vacancy-card');
  div.innerHTML = `
          <div class="vacancy-title">
            ${title}
          </div>
          <div class="hashtags">
            ${hashTags}
          </div>
          <div class="right-arrow">
            <img src="${rightArrow}" alt="open">
          </div>
          <div class="read-more">
            Read more
          </div>
    `;
  return div;
};
