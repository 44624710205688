export const Resolutions = {
  width: {
    desktop: 1024,
    iPad: 768,
  },
  heights: {
    laptop: 630,
    iPad: 900,
    pc: 960,
    wideScreenPc: 1300,
  },
};
