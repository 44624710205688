import debounce from 'lodash.debounce';
import JoinUsController from '../JoinUsController';
import { Resolutions } from '../constants/Resolutions';
import HelperService from '../services/helperService';
import * as services from './services';
import { PAGES } from './constants';

class NavigationController {
  constructor() {
    this.pages = PAGES;
    this.isDesctop = null;
    this.isIpad = null;
    this.menuIsOpen = false;
    this.currentPage = null;
    this.navBar = document.querySelector('.navbar-menu');
    this.menuPanel = document.querySelector('.slide-side-panel');
    this.menuList = document.querySelector('.menu-list');
    this.navbarIcon = document.querySelector('.bars');
    this.joinAsController = new JoinUsController();
    this.handleResizeWindow = this.handleResizeWindow.bind(this);
    const debounceResize = debounce(this.handleResizeWindow, 100);
    this.handleNavItemClick = this.handleNavItemClick.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    this.swipeLeft = this.swipeLeft.bind(this);
    this.swipeRight = this.swipeRight.bind(this);

    this.menuList.addEventListener('click', this.handleNavItemClick);
    this.navbarIcon.addEventListener('click', this.openMobileMenu.bind(this));

    document.addEventListener('DOMContentLoaded', this.onDomContentLoaded.bind(this));
    window.addEventListener('resize', debounceResize);
    document.body.addEventListener('swipeleft', this.swipeLeft);
    document.body.addEventListener('swiperight', this.swipeRight);

    if (this.navBar) {
      this.navBar.addEventListener('click', this.handleNavItemClick);
    }
  }

  swipeLeft() {
    const isDesktopOrIpad = this.isIpad || this.isDesctop;
    if (this.menuIsOpen
      || this.joinAsController.slidePanelIsOpen
      || isDesktopOrIpad
    ) {
      return;
    }

    this.openMobileMenu();
  }

  swipeRight() {
    if (this.menuIsOpen) {
      this.closeMobileMenu();
      return;
    }
    if (this.joinAsController.slidePanelIsOpen) {
      this.joinAsController.closeSlidePanel();
    }
  }

  onDomContentLoaded() {
    this.handleResizeWindow();
    const pages = document.getElementsByClassName('page');
    this.pages = this.pages.map((page, i) => {
      return {
        section: pages[i],
        ...page,
      };
    });
    [this.currentPage] = [...this.pages];
  }

  handleResizeWindow() {
    const { body } = document;
    const isDesktop = body.clientHeight >= Resolutions.heights.laptop
      && body.clientWidth >= Resolutions.width.desktop;
    const isIpad = !isDesktop && (body.clientHeight >= Resolutions.heights.iPad
      && body.clientWidth >= Resolutions.width.iPad);
    const isDesktopOrIpad = isDesktop || isIpad;

    if (isDesktopOrIpad !== (this.isDesctop || this.isIpad)) {
      const backgrounds = document.querySelectorAll('.fullscreen-bg');
      if (isDesktopOrIpad) {
        services.addSources(backgrounds);

        if (this.menuIsOpen) {
          this.closeMobileMenu();
        }
      } else {
        services.clearSources(backgrounds);
      }
    }

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.isIpad = isIpad;
    this.isDesctop = isDesktop;
    this.joinAsController.setDeviceType(!isDesktop, isIpad);
  }

  openMobileMenu() {
    const blackout = document.querySelector('.blackout');
    this.menuPanel.classList.add('open-like-menu');
    blackout.classList.add('active');
    blackout.addEventListener('click', this.closeMobileMenu);
    this.menuIsOpen = true;
    document.activeElement.blur();
  }

  closeMobileMenu() {
    const blackout = document.querySelector('.blackout');
    setTimeout(() => {
      this.menuPanel.classList.remove('open-like-menu');
      this.menuIsOpen = false;
    }, 150);
    blackout.classList.remove('active');
  }

  selectMenuItem(colorName) {
    this.menuList.childNodes.forEach((element) => {
      if (!element.classList) {
        return;
      }
      element.classList.remove('selected');
      if (element.dataset.color === colorName) {
        element.classList.add('selected');
      }
    });
  }

  handleNavItemClick(e) {
    const { color } = e.target.dataset;
    const newPage = this.pages.find(page => page.colorName === color);
    if (newPage) {
      this.navigate(newPage);
    }
  }

  navigate(newPage) {
    const { colorName, name } = newPage;
    if (name === this.currentPage.name
      || this.isLoading
      || this.joinAsController.slidePanelIsOpen
    ) {
      return;
    }

    this.isLoading = true;
    const isDesktopOrIpad = this.isIpad || this.isDesctop;
    const selectedLink = this.navBar.querySelector(`[data-color="${colorName}"]`);
    const transitionBlock = document.querySelector('.transition-block');
    transitionBlock.classList.remove('hide');
    transitionBlock.dataset.color = colorName;
    transitionBlock.classList.add('animate');

    if (this.menuIsOpen) {
      this.closeMobileMenu();
    }

    setTimeout(() => {
      this.changePage(newPage);
      HelperService.removeClassFromElementChildes(this.navBar, 'selected');
      selectedLink.classList.add('selected');
    }, 500 * isDesktopOrIpad);
    setTimeout(() => {
      transitionBlock.classList.remove('animate');
      transitionBlock.classList.add('hide');
    }, 1500 * isDesktopOrIpad);
    setTimeout(() => {
      this.isLoading = false;
    }, 1600 * isDesktopOrIpad);
  }

  changePage(newPage) {
    const { colorName, section } = newPage;
    const oldPageElement = this.currentPage.section;
    const newPageElement = section;
    services.changePage(newPageElement, oldPageElement);

    this.selectMenuItem(colorName);
    this.currentPage = newPage;
  }
}

const navigationController = new NavigationController();
export default navigationController;
