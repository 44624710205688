import navigationController from './NavigationController';
import sayHelloController from './SayHelloController';
import * as svg from './svg';
import tocca from 'tocca/Tocca';

if (window.location.host === 'bitnet.by') {
  const div = document.getElementById('google-analytics');
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-146917351-1');
  div.appendChild(script);
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  gtag('js', new Date());

  gtag('config', 'UA-146917351-1');
}

if (module.hot) {
  module.hot.accept();
}
