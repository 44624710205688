
export const setSlidePanelContent = ( sections, description, title) => {
  const uls = sections.map((section) => {
    let childs = '';
    section.childs.forEach((objective) => {
      const { text } = objective;
      childs = childs.concat(`<li>${text}</li>`);
    });
    return `<span class="list-title">${section.title}</span>
                <ul>
                  ${childs}
                </ul>`;
  });

  const descriptionContainer = description && `<div class="description">
              ${description}
            </div>`;
  return `
          <button type="submit" class="btn edit">
            <span class="apply">Apply now</span>
            <span class="send">Send</span>
            <span class="sending">Sending...</span>
          </button>
          <div class="vacancy-info-container_title">
             <img class="left-arrow" src="./assets/Vector.svg" alt="close"/>
             <span>${title}</span>
          </div>
          <div class="bottom-white-block"></div>
          <div class="info">
            ${descriptionContainer}
            <div class="list-container">
              ${uls.join('')}
            </div>
            <div class="form-container">
              <div class="form-title">Application form</div>
              <form>
                <div class="form-group">
                  <label for="inputName">Name</label>
                  <input type="text" class="form-control" id="inputName" placeholder="Your name">
                </div>
                <div class="form-group">
                  <label for="inputEmail">Email</label>
                  <input type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Email">
                </div>
                <div class="form-group">
                  <label for="inputAdditionalInfo">Attachments</label>
                  <input type="text" class="form-control" id="inputAdditionalInfo" placeholder="Github, portfolio or what you want to share">
                </div>
              </form>
           </div>
         </div>
    `;
};
