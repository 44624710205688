export const getFilter = (title, count, isSelected) => {
  const button = document.createElement('button');
  button.innerHTML = `
            <span class=${title}>${title}</span>
            <span class=${title}>${count}</span>
          `;
  button.classList.add(title);

  isSelected && button.classList.add('selected');

  return button;
};
