import DOMPurify from 'dompurify';
import showdown from 'showdown';

const showdownConverter = new showdown.Converter({
  headerLevelStart: 3,
});
const animationTime = 200;
const framesCount = 60;

export const animatedScrollToEnd = (container) => {
  const coordY = container.scrollHeight - container.clientHeight;

  // Edge has bug with scrollBy, scrollTo
  const scroller = setInterval(() => {
    const scrollBy = coordY / framesCount;
    if (scrollBy > container.scrollTop - coordY
      && container.scrollTop + 10 < coordY) {
      container.scrollTop += scrollBy;
    } else {
      container.scrollTop = coordY;
      clearInterval(scroller);
    }
  }, animationTime / framesCount);
};

const markText = (text) => {
  return DOMPurify.sanitize(showdownConverter.makeHtml(text));
};

const mapSections = (sections) => {
  return sections.map((section) => {
    const { childs, ...sectionData } = section;

    const markedChilds = childs.map((child) => {
      const { text } = child;

      return {
        text: markText(text),
      };
    });

    return {
      childs: markedChilds,
      ...sectionData,
    };
  });
};

export const mapVacansiesData = (vacansies) => {
  return vacansies.map((vacancy) => {
    const {
      sections, is_hot, description, ...vacancyData
    } = vacancy;

    const markedSections = mapSections(sections);

    return {
      isHot: is_hot,
      description: markText(description),
      sections: markedSections,
      ...vacancyData,
    };
  });
};

