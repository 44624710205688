const EMAIL_REGEXP = /^((?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]|(\".+\"))+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;

export default class HelperService {
  static removeClassFromElementChildes(element, className) {
    element.childNodes.forEach((element) => {
      if (element.classList) {
        element.classList.remove(className);
      }
    });
  }

  static emailIsValid(value) {
    return value && EMAIL_REGEXP.test(value.toLowerCase());
  }


  static showError(element) {
    element.closest('.form-group')
      .classList
      .add('error');
  }

  static hideError(element) {
    element.closest('.form-group')
      .classList
      .remove('error');
  }
}
