import { VACANCIES_APPLY, getUrl, headers } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const applyVacancies = (name, email, reference, vacancy) => fetch(getUrl(VACANCIES_APPLY), {
  method: 'post',
  headers,
  body: JSON.stringify({
    name,
    email,
    reference,
    vacancy,
  }),
});
