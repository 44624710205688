import { VACANCIES, getUrl, headers } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const getVacancies = () => {
  return fetch(`${getUrl(VACANCIES)}?limit=100`, {
    method: 'get',
    headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data.results;
    })
    .catch((e) => {
    });
};
