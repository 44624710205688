import helperService from '../services/helperService';

export class JoinUsFormValidator {
  constructor(form, submitButton) {
    this.form = form;
    this.submitButton = submitButton;
    this.nameInput = this.form.querySelector('#inputName');
    this.emailInput = this.form.querySelector('#inputEmail');

    this.validateNotEmpty = this.validateNotEmpty.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.nameInput.addEventListener('keyup', this.validateNotEmpty);
    this.emailInput.addEventListener('keyup', this.validateEmail);
  }

  validateNotEmpty() {
    if (this.formIsValid()) {
      this.submitButton.classList.add('submit');
    } else {
      this.submitButton.classList.remove('submit');
    }
    this.addFormButtonClass();
  }

  addFormButtonClass() {
    if (!this.submitButton.classList.contains('edit')) {
      this.submitButton.classList.add('edit');
    }
  }

  validateEmail(e) {
    const element = e.target;
    const fieldIsValid = helperService.emailIsValid(element.value);

    if (fieldIsValid) {
      helperService.hideError(element);
    } else {
      helperService.showError(element);
    }
    this.addFormButtonClass();

    if (this.formIsValid()) {
      this.submitButton.classList.add('submit');
    } else {
      this.submitButton.classList.remove('submit');
    }
  }

  formIsValid() {
    return (
      !!this.nameInput.value &&
      helperService.emailIsValid(this.emailInput.value)
    );
  }

  formIsEmpty() {
    return !this.nameInput.value && !this.emailInput.value;
  }

  destroy() {
    this.nameInput.removeEventListener('keyup', this.validateNotEmpty);
    this.emailInput.removeEventListener('keyup', this.validateEmail);
  }
}
