export const PAGES = [
  {
    name: 'who we are',
    colorName: 'blue',
  },
  {
    name: 'join us',
    colorName: 'orange',
  },
  {
    name: 'say hello',
    colorName: 'green',
  },
];
